/** General styles **/

::selection {
  color: #ffffff !important;
  background: #c63627 !important;
}

a,
button {
  transition: 0.3s all;
}

a {
  text-decoration: none;
}

html,
body {
  font-family: "Nunito Sans", sans-serif;
  background: #eef1f2;
  overflow: unset !important;
}

.App .inner-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

img {
  max-width: 100%;
}

main {
  margin-bottom: 60px;
}

/** Bizzll word **/

.bizzll-word {
  font-family: Impact, sans-serif;
  font-style: italic;
  font-weight: 900;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 2px;
  font-size: 120%;
  display: inline-block;
}

.bizzll-word span {
  display: inline-block !important;
  text-transform: none !important;
}

.bizzll-word .b {
  color: #c63627 !important;
}

.bizzll-word .i {
  color: #fcc204 !important;
}

.bizzll-word .z {
  color: #93cc04 !important;
}

.bizzll-word .zz {
  color: #05704f !important;
}

.bizzll-word .l {
  color: #2aa4f6 !important;
}

.bizzll-word .ll {
  color: #8620ce !important;
}

@media only screen and (max-width: 768px) {
  .Bizzll-video {
    width: 100% !important;
    height: auto !important;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 768px) {
  .Bizzll-video {
    width: 50% !important;
    height: auto !important;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .directory-search-icon {
    background-color: #8620ce;
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .bizzll-video-player {
    padding: 0px 50px;
  }
}

@media only screen and (min-width: 768px) {
  .directory-search-button {
    background-color: #8620ce;
    color: #fff;
    display: none;
  }
}

#page-title .post-space {
  width: 60%;
  z-index: 100;
  border-radius: 5px;
  overflow: hidden;
}

.advertisement-img {
  width: 100%;
  height: 90px;
}
.banner-advertisement-img {
  width: 100% !important;
  height: 90px !important;
}

/** Featured Slider Carousel **/

#verification-form {
  padding: 0 24%;
}

.bizzll-live-tv {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bolder;
  margin-bottom: 15px;
}

/* @media only screen and (min-width: 768px) { */
h1 {
  font-size: 2rem;
}

/* } */

/* @media only screen and (max-width: 768px) {
  h1 {
    font-size: 1rem;
  }
} */

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 25px;
  font-size: 1rem;
}

.page-content ul {
  margin-left: 35px;
}

.page-content ul li {
  list-style-type: disc;
}

.inviteTextarea {
  width: 100%;
  margin-top: 10px;
}

.formDescription {
  padding-bottom: 20px;
}

.affiliateReferralLink {
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-size: 20px;
  padding: 100px;
}

/* .ad-space {
  cursor: pointer;
} */

.about-container {
  padding: 20px !important;
  border: 3px solid #0049FF;
  border-radius: 15px 15px 0 15px;
  box-shadow: 1px 1px 1px rgba(1, 1, 1, 0.3);
}